import React from "react"
import PropTypes from "prop-types"
import './_Container.css';

const Container = ({ className, children, size }) => {
    const baseName = "sk-Container";
    const classNames = [
        baseName,
        size ? `${baseName}--${size}` : null,
        className,
    ];

    return (
        <div className={classNames.join(' ')}>
            {children}
        </div>
    )
}

Container.propTypes = {
    size: PropTypes.string,
}

export default Container
