import React from "react";
import { Link } from "gatsby";
import Container from "../../layout/Container";

import "./_Navbar.scss";
import logo from "../../images/logo-schreinerei-kraiser.svg";

const Navbar = () => {
    const baseName = "sk-Navbar";
    const classNames = [
        baseName,
    ];

    return (
        <div className={classNames.join(' ')}>
            <Container>
                <Link to="/">
                    <img 
                        className={`${baseName}__logo`} 
                        src={logo} 
                        alt="Schreinerei Kraiser Logo" 
                    />
                </Link>
            </Container>
        </div>
    )
}

export default Navbar
