import React from "react";
import PropTypes from "prop-types";
import './_Breaker.scss';

const Breaker = ({ image, alt }) => {
    const baseName = "sk-Breaker";
    const classNames = [
        baseName,
    ];

    return (
        <div className={classNames.join(' ')}>
            <img
                className={`${baseName}__image`}
                src={image}
                alt={alt}
            />
        </div>
    )
}

Breaker.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
}

Breaker.defaultProps = {
    alt: "",
}

export default Breaker
