import React from "react";
import Container from "../../layout/Container";
import Headline from "../Headline";

import './_ImageGrid.scss';

const ImageGrid = ({ items }) => {
    const baseName = "ImageGrid";
    const classNames = [
        baseName,
    ];

    return (
        <div className={classNames.join(' ')}>
            <Container className={`${baseName}__inner`}>
                <Headline
                    className={`${baseName}__title`}
                    as="h2"
                    size="secondary"
                >
                    Einige unserer Arbeiten
                </Headline>
                <div className={`${baseName}__images`}>
                    {
                        items.map(item => {
                            return (
                                <div key={item.id} className={`${baseName}__item`}>
                                    <img className={`${baseName}__image`} src={item.src} alt={item.alt} />
                                </div>
                            );
                        })
                    }
                </div>
            </Container>
        </div>
    )
}

export default ImageGrid
