import React from "react";
import config from "../../../gatsby-config.js";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";


export default function({ children, pageTitle }) {
    const baseName = "sk-Layout";
    const classNames = [
        baseName,
    ];

    return (
        <div className={classNames.join(' ')}>
            <Helmet>
                <title>{config.siteMetadata.title}{pageTitle ? `- ${pageTitle}` : ''}</title>
                <meta name="description" content={config.siteMetadata.description} />
            </Helmet>
            <Navbar />
            <main className={`${baseName}__main`}>
                {children}
            </main>
            <Footer />
        </div>
    )
}