module.exports = {
  siteMetadata: {
    title: `Schreinerei Kraiser | Beuren 72660`,
    description: `Schreinerei Kraiser mit Sitz in Beuren bei Nürtingen fertigt allerlei Möbelstücken nach Maß ebenso Altbaumöbel und ist als Meisterbetrieb Partner im Innenausbau, Ladenbau und Raumgestaltung.`,
    author: ``,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        icon: `src/images/favicon.png`, // This path is relative to the root of the site.
      },
    },
    `gatsby-plugin-sass`,
    `gatsby-plugin-lodash`,
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
