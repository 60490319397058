import React from "react";
import PropTypes from "prop-types";
import './_Button.scss';

const Button = ({ className, children, as, href, value }) => {
    const baseName = "sk-Button";
    const classNames = [
        baseName,
        className,
    ];
    const Element = as;
    const buttonText = children || value;

    return (
        <Element
            className={classNames.join(' ')}
            href={href}
        >
            {buttonText}
        </Element>
    );
}

Button.propTypes = {
    as: PropTypes.string,
}

Button.defaultProps = {
    as: "button",
}

export default Button;