import React, { useEffect, useState } from "react"
import { withPrefix } from 'gatsby'
import _ from "lodash";
import objectFitImages from 'object-fit-images';
import Intro from "../components/Intro";
import Layout from "../layout/Layout";
import Breaker from "../components/Breaker";
import TextGrid from "../components/TextGrid";
import ImageGrid from "../components/ImageGrid";

import gebauede01 from "../images/breaker/schreinerei-kraiser-beuren-gebaeude.jpg";
import gebauede02 from "../images/breaker/schreinerei-kraiser-beuren-gebaeude-02.jpg";
import people from "../images/icons/people.svg";
import heart from "../images/icons/heart.svg";
import plane from "../images/icons/plane.svg";

const services = [
    {
        title: "Individualität zeigt Charakter",
        copy: "Wohnzimmermöbel, Schlafzimmer, Esszimmer- Möbel, Küchen- und Badmöbel planen, gestalten und führen wir für Sie nach Ihren Anforderungen aus. Auch bei Betten, Eckbänke, Ofenbänke, begehbaren Kleiderschränken, Kommoden, etc. sind wir Ihr kompetenter Meisterbetrieb und beraten Sie mit unseren Erfahrungen.",
        icon: plane,
    },
    {
        title: "Alte Werte bewahren",
        copy: "Mit Freude und Liebe zum Detail, nehmen wir auch Ihre Spezialaufträge z.B. im Bereich Denkmalschutz und bewahren die Schätze im Innenausbau und von Altbaumöbeln. Dabei ist die Fertigung und Bearbeitung die eigenen CNC Maschinen im Hause ein wichtiges Qualitätsmerkmal.",
        icon: heart,
    },
    {
        title: "Regional verwurzelt",
        copy: "Für unsere Kunden sind wir vom Neuffener Täle rund um Beuren über Nürtingen, Metzingen, Bad Urach, Kirchheim unter Teck, Esslingen und Göppingen tätig. Wir informieren, beraten und erstellen für Sie gerne ein Angebot und freuen uns über Ihre Anfrage!",
        icon: people,
    },
];

const references = [
    {
        id: _.uniqueId(),
        src: withPrefix('/references/referenz-01.jpg'),
        alt: ""
    },
    {
        id: _.uniqueId(),
        src: withPrefix('/references/referenz-02.jpg'),
        alt: ""
    },
    {
        id: _.uniqueId(),
        src: withPrefix('/references/referenz-03.jpg'),
        alt: ""
    },
    {
        id: _.uniqueId(),
        src: withPrefix('/references/referenz-04.jpg'),
        alt: ""
    },
    {
        id: _.uniqueId(),
        src: withPrefix('/references/referenz-05.jpg'),
        alt: ""
    },
    {
        id: _.uniqueId(),
        src: withPrefix('/references/referenz-06.jpg'),
        alt: ""
    },
];


const IndexPage = () => {
    const [breakerImage, setBreakerImage] = useState(null);

    useEffect(() => {
        objectFitImages();
        setBreakerImage(window.innerWidth >= 414 ? gebauede01 : gebauede02);
    });

    return (
        <div className="sk-index">
            <Layout>
                <Intro />
                <Breaker image={breakerImage} />
                <TextGrid items={services} />
                <ImageGrid items={references} />
            </Layout>
        </div>
    );
}

export default IndexPage
