import React from "react";
import Container from "../../layout/Container";
import Headline from "../Headline";
import './_TextGrid.scss';

const TextGrid = ({ items }) => {
    const baseName = "sk-TextGrid";
    const classNames = [
        baseName,
    ];

    return (
        <div className={classNames.join(' ')}>
            <Container className={`${baseName}__inner`}>
                {
                    items.map(item => {
                        return (
                            <div key={item.title} className={`${baseName}__item`}>
                                <img className={`${baseName}__icon`} src={item.icon} alt="" />
                                <Headline
                                    className={`${baseName}__title`}
                                    as="h3"
                                    size="secondary"
                                >
                                    {item.title}
                                </Headline>
                                <p className={`${baseName}__copy`}>{item.copy}</p>
                            </div>
                        );
                    })
                }
            </Container>
        </div>
    )
}

export default TextGrid
