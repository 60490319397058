import React from "react";
import Container from "../../layout/Container";
import Headline from "../Headline";
import Button from "../Button";

//import arrow from "../../images/icons/arrow-right.svg";
import './_Intro.scss';

const Intro = () => {
    const baseName = "sk-Intro";
    const classNames = [
        baseName,
    ];

    return (
        <div className={classNames.join(' ')}>
            <Container className={`${baseName}__inner`}>
                <Headline className={`${baseName}__pretitle`} size="secondary">Holz schafft Lebensraum – und wir schaffen Lebensqualität</Headline>
                <Headline className={`${baseName}__title`} as="h1" size="h1">Als Schreinermeisterbetrieb, seit über 45 Jahren in Beuren, liefern wir nichts von der Stange, sondern erfüllen Ihre Wünsche von einzelnen Möbelstücken nach Maß, Objektmöbeln, Zimmertüren, Haustüren, Wand, Decken und Holzverkleidungen, Messebau, Ladenausstattung, Raumgestaltung, Empfangstheken bis hin zum kompletten Innenausbau.</Headline>
                <Button as="a" href="#kontakt" value="Kontakt" />
            </Container>
        </div>
    )
}

export default Intro
