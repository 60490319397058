import React from "react";
import { Link } from "gatsby";
import Container from "../../layout/Container";
import Headline from "../Headline";

import './_Footer.scss';

const Footer = () => {
    const baseName = "sk-Footer";
    const classNames = [
        baseName,
    ];

    return (
        <div id="kontakt" className={classNames.join(' ')}>
            <Container>
                <div className={`${baseName}__inner`}>
                    <Headline
                        className={`${baseName}__title`}
                        size="h1"
                    >
                        Schreinerei Kraiser
                    </Headline>
                    <div className={`${baseName}__contact`}>
                        <div>
                            <Headline size="secondary">Kontakt</Headline>
                            <p>
                                Telefon: 0 70 25 / 23 25
                                <br /> Telefax: 0 70 25 / 45 33
                                <br/>
                                <br/> <a href="mailto:info@schreinerei-kraiser.de">info@schreinerei-kraiser.de</a>
                            </p>
                        </div>
                        <div>
                            <Headline size="secondary">Anschrift</Headline>
                            <p>
                                Morglachstraße 3
                                <br /> 72660 Beuren
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${baseName}__nav`}>
                    <Link className={`${baseName}__navItem`} to="/impressum">Impressum</Link>
                    <Link className={`${baseName}__navItem`} to="/datenschutz">Datenschutz</Link>
                </div>
            </Container>
        </div>
    )
}

export default Footer
